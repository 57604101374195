<template>
  <div class="evaluate">
    <div class="tips">
      评价须知：
      评委您好！非常感谢您对《麻省理工科技评论》2021年中国区榜单评选活动的支持。评选时，每位参选人的材料（详见报名信息详情）包含个人简历、个人陈述、2-4封推荐信和问题环节。满分为10分，1分代表差，10分代表非常杰出。评分时，建议您将参选人和您所审阅的其他同领域参选人进行比较。另外，我们衷心的希望您可以为每位您所审阅的参选人写下评语，一个简短的评价将帮助我们理解参选人创新工作的突破之处和影响力。（注：提交评分之前，您可以进行多次修改，直至您提交评分。）
    </div>
    <div class="maintitle">评审信息</div>
    <el-form label-position="right" label-width="150px">
      <el-form-item label="评分:" required prop="rate">
        <el-rate
          v-model="value"
          :max="10"
          allow-half
          @change="handleChange"
          show-score
          score-template="{value}分"
          :disabled="!isEdit"
        />
      </el-form-item>
      <el-form-item label="评语:" required prop="text">
        <el-input
          v-model="text"
          placeholder="请输入您的评语"
          show-word-limit
          maxlength="300"
          type="textarea"
          resize="none"
          v-if="isEdit"
        />
        <div v-else class="text">很好啊很好啊</div>
      </el-form-item>
      <el-form-item label="" class="btns" v-if="isEdit">
        <el-button type="primary" @click="submitForm">保存</el-button>
      </el-form-item>
    </el-form>
    <div class="downtitle">
      <div class="maintitle">候选人信息</div>
      <span v-if="isEdit">下载所有材料</span>
    </div>
    <div class="downlist">
      <div class="item">
        <div class="lable">候选人姓名:</div>
        <div class="name">王多鱼/Duoyu Wang</div>
      </div>
      <div class="item">
        <div class="cn">
          <div class="lable">简历（中文）:</div>
          <div class="filelist">
            <div class="file">王多鱼_中文个人陈述.pdf</div>
          </div>
        </div>
        <div class="en">
          <div class="lable">简历（英文）:</div>
          <div class="filelist">
            <div class="file">王多鱼_中文个人陈述.pdf</div>
            <div class="file">王多鱼_中文个人陈述.pdf</div>
            <div class="file">王多鱼_中文个人陈述.pdf</div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="cn">
          <div class="lable">个人陈述（中文）:</div>
          <div class="filelist">
            <div class="file">王多鱼_中文个人陈述.pdf</div>
            <div class="file">王多鱼_中文个人陈述.pdf</div>
            <div class="file">王多鱼_中文个人陈述.pdf</div>
          </div>
        </div>
        <div class="en">
          <div class="lable">个人陈述（英文）:</div>
          <div class="filelist">
            <div class="file">王多鱼_中文个人陈述.pdf</div>
            <div class="file">王多鱼_中文个人陈述.pdf</div>
            <div class="file">王多鱼_中文个人陈述.pdf</div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="cn">
          <div class="lable">推荐信（中文）:</div>
          <div class="filelist">
            <div class="file">王多鱼_中文推呵呵哈哈哈或荐信.pdf</div>
            <div class="file">王多鱼_中文推荐信.pdf</div>
          </div>
        </div>
        <div class="en">
          <div class="lable">推荐信（英文）:</div>
          <div class="filelist">
            <div class="file">王多鱼_中文个人陈述呵呵哈哈哈或.pdf</div>
            <div class="file">王多鱼_中文个人陈述.pdf</div>
            <div class="file">王多鱼_中文个人陈述.pdf</div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="cn">
          <div class="lable">补充信息:</div>
          <div class="filelist">
            <div class="file">王多鱼_中文推荐信.pdf</div>
            <div class="file">王多鱼_中文推荐信.pdf</div>
          </div>
        </div>
      </div>
    </div>
    <div class="maintitle">创新问题</div>
    <div class="reqlist">
      <div class="item">
        <div class="title"
          >1.请描述一下您一路走来，在不同求学与科研阶段（硕/博/博后/目前）研究项目？主要的侧重点为何？在各个阶段取得的突破主要是哪些？当初为何选择这些方向投入？</div
        >
        <div class="rescn"
          ><span>中文回答:</span
          >请描述一下您一路走来，在不同求学与科研阶段（硕/博/博后/目前）研究项目？主要的侧重点为何？在各个阶段取得的突破主要是哪些？当初为何选择这些方向投入？</div
        >
        <div class="resen"
          ><span>英文回答:</span
          >请描述一下您一路走来，在不同求学与科研阶段（硕/博/博后/目前）研究项目？主要的侧重点为何？在各个阶段取得的突破主要是哪些？当初为何选择这些方向投入？</div
        >
      </div>
      <div class="item">
        <div class="title"
          >1.请描述一下您一路走来，在不同求学与科研阶段（硕/博/博后/目前）研究项目？主要的侧重点为何？在各个阶段取得的突破主要是哪些？当初为何选择这些方向投入？</div
        >
        <div class="rescn"
          ><span>中文回答:</span
          >请描述一下您一路走来，在不同求学与科研阶段（硕/博/博后/目前）研究项目？主要的侧重点为何？在各个阶段取得的突破主要是哪些？当初为何选择这些方向投入？</div
        >
        <div class="resen"
          ><span>英文回答:</span
          >请描述一下您一路走来，在不同求学与科研阶段（硕/博/博后/目前）研究项目？主要的侧重点为何？在各个阶段取得的突破主要是哪些？当初为何选择这些方向投入？</div
        >
      </div>
      <div class="item">
        <div class="title"
          >1.请描述一下您一路走来，在不同求学与科研阶段（硕/博/博后/目前）研究项目？主要的侧重点为何？在各个阶段取得的突破主要是哪些？当初为何选择这些方向投入？</div
        >
        <div class="rescn"
          ><span>中文回答:</span
          >请描述一下您一路走来，在不同求学与科研阶段（硕/博/博后/目前）研究项目？主要的侧重点为何？在各个阶段取得的突破主要是哪些？当初为何选择这些方向投入？</div
        >
        <div class="resen"
          ><span>英文回答:</span
          >请描述一下您一路走来，在不同求学与科研阶段（硕/博/博后/目前）研究项目？主要的侧重点为何？在各个阶段取得的突破主要是哪些？当初为何选择这些方向投入？</div
        >
      </div>
    </div>
    <div class="editbtns" v-if="!isEdit">
      <div class="edit" @click="() => (isEdit = true)">修改评价</div>
      <div class="back">返回</div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import { ref } from 'vue'
  export default defineComponent({
    components: {},
    setup() {
      const value = ref(null)
      const text = ref(null)
      const isEdit = ref(false)
      const submitForm = () => {}
      const handleChange = (value) => {
        // console.log('value', value)
      }
      return {
        submitForm,
        value,
        text,
        handleChange,
        isEdit
      }
    }
  })
</script>
<style lang="scss" scoped>
  .evaluate {
    padding: 30px 40px 70px;
    .tips {
      padding: 20px;
      background: #fef3d6;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
    .maintitle {
      border-left: 8px solid #e34c3e;
      font-size: 21px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      padding-left: 8px;
      margin: 50px 0 0 44px;
    }
    .el-form-item:nth-of-type(1) {
      margin-top: 30px;
      margin-bottom: 24px;
    }
    .el-form-item:nth-of-type(2) {
      :deep(.el-form-item__content) {
        line-height: normal;
      }
    }
    :deep(.el-form-item__label) {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    :deep(.el-rate__icon) {
      font-size: 40px;
    }
    :deep(.el-rate__text) {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    :deep(.el-textarea),
    :deep(.el-textarea__inner) {
      width: 814px;
      height: 120px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .text {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      margin-top: 5px;
    }
    .btns {
      :deep(.el-form-item__content) {
        width: 814px;
        flex: none;
        display: flex;
        justify-content: center;
        .el-button--primary {
          width: 150px;
          height: 50px;
          background: #e34c3e;
          border-radius: 4px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          border: none;
          margin: 50px auto 0;
        }
      }
    }
    .downtitle {
      display: flex;
      align-items: center;
      margin-top: 50px;
      .maintitle {
        margin-top: 0;
      }
      > span {
        display: inline-block;
        width: 124px;
        height: 34px;
        background: #e34c3e;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 34px;
        margin-left: 40px;
        cursor: pointer;
      }
    }
    .downlist {
      padding: 0 70px;
      .item {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        // margin-top: 20px;
        .lable {
          width: 130px;
          text-align: right;
          margin-right: 16px;
          font-size: 15px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #262626;
        }
        .name {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .file {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #448df7;
          margin-bottom: 20px;
          cursor: pointer;
          overflow: hidden;
          width: 260px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .cn,
        .en {
          display: flex;
          // align-items: center;
        }
      }
      .item:nth-of-type(1) {
        margin-top: 27px;
        margin-bottom: 20px;
        justify-content: start;
      }
    }
    .reqlist {
      padding: 0 70px;
      .item {
        margin-top: 30px;
        .title {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
        }
        .rescn,
        .resen {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          > span {
            font-size: 15px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #262626;
          }
        }
        .rescn {
          margin-top: 30px;
        }
        .resen {
          margin-top: 20px;
        }
      }
    }
    .editbtns {
      width: fit-content;
      display: flex;
      margin: 70px auto 0;
      > div {
        width: 150px;
        height: 50px;
        border-radius: 4px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
      }
      .edit {
        background: #e34c3e;
        color: #ffffff;
        margin-right: 60px;
      }
      .back {
        border: 1px solid #bbbbbb;
        color: #000000;
      }
    }
  }
</style>
